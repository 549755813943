import { EltrpPage } from "../../../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../../../layout/wrappers/eltrp-header";
import { EltrpBody } from "../../../layout/wrappers/eltrp-body";
import { ChangePassword } from "../components/change-password";
import { EltrpText } from "../../../layout/wrappers/eltrp-text";
import { DeleteAccount } from "../components/delete-account";
import { UserAttributes } from "../components/user-attributes";
import { ImportFavourites } from "../components/import-favourites";

export const UserSettingsPage = () => {
  return (
    <EltrpPage>
      <EltrpHeader title={"User Settings"} />
      <EltrpBody>
        <EltrpText>
          <h2> User Attributes</h2>
          <UserAttributes />
        </EltrpText>
        <EltrpText>
          <h2> Change Password </h2>
          <ChangePassword />
        </EltrpText>
        <EltrpText>
          <h2> Import Local Favourites </h2>
          <p>
            {" "}
            Import the articles and tags you saved locally to your account. If
            you saved articles and created tags before having an account, or
            forgot to sign in, you can import your saved articles. Importing
            locally saved favourites will not replace your current favourites,
            only add to them.
          </p>
          <ImportFavourites />
        </EltrpText>
        <EltrpText>
          <h2> Delete Account</h2>
          <DeleteAccount />
        </EltrpText>
      </EltrpBody>
    </EltrpPage>
  );
};
