import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { TagCategoriesForm } from "./tag-categories-form";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { TagCategoriesSelect } from "./tag-categories-select";

export interface TagCategoriesProps {
  tagFilter: string[];
  setTagFilter: Dispatch<SetStateAction<string[]>>;
}

export const TagCategoriesAggregation = ({
  tagFilter,
  setTagFilter,
}: TagCategoriesProps) => {
  const [open, setOpen] = useState<boolean>(false);



  return (
    <Box>
      <h2>{`Tag Categories ${tagFilter.length ? `(${tagFilter.length})` : ""}`}</h2>
      <i>Show articles with tags </i>

      <Box pt={1} pr={1} pb={1}>
        <TagCategoriesSelect
          tagFilter={tagFilter}
          setTagFilter={setTagFilter}
        />
      </Box>
      <ActionButton
        title={"Manage Tag Categories"}
        onClick={() => setOpen(true)}
      />

      <EltrpModal
        title={"Manage Tag Categories"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <TagCategoriesForm />
      </EltrpModal>
    </Box>
  );
};
