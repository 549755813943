import { CloseSharp, LocalOffer } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { useFavourites } from "../../../utils/context/favourites-context";
import { useAuth } from "../../../utils/context/auth-context";
import { useAlert } from "../../../utils/context/alert-context";
import { useNavigate } from "react-router-dom";

interface ISaveTagsProps {
  articleId: string;
  saved: boolean;
}

export const SaveTags = (props: ISaveTagsProps) => {
  const { articleId, saved } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>([]);
  const [reminderOpen, setReminderOpen] = useState<boolean>(false);

  const favourites = useFavourites();
  const auth = useAuth();
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setTags(favourites.getTagsFromArticle(articleId));
  }, [articleId, favourites]);

  const tagList = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  const submit = (value: any) => {
    value.tags && favourites.updateTagsOnArticle(articleId, value.tags);
    setTags(favourites.getTagsFromArticle(articleId));
    setOpen(false);
  };

  const pushValue = (
    values: any,
    input: any,
    push: (...args: any[]) => any,
  ) => {
    if (!values["tags"].includes(input) && !!input.trim()) {
      if (!auth.authenticated && values["tags"].length >= 10) {
        alert.handleAlert({
          message: "Maximum number of tags reached",
          statusCode: 400,
          persistent: true,
        });
        return;
      }
      push("tags", input);
      !tagList.includes(input) && favourites.addTag(input);
    }
  };

  const handleClose = () => {
    auth.clearFavouritesReminder();
    setReminderOpen(false);
  };

  const handleClick = () => {
    setReminderOpen(false);
    navigate("/sign-in");
  };

  const action = (
    <Box display={"flex"} gap={1}>
      <Button
        color={"inherit"}
        size={"small"}
        variant={"outlined"}
        onClick={handleClick}
      >
        {" "}
        Sign in{" "}
      </Button>
      <Button
        color={"inherit"}
        size={"small"}
        variant={"outlined"}
        onClick={handleClose}
      >
        {" "}
        Don't show again{" "}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setReminderOpen(false)}
      >
        <CloseSharp fontSize={"small"} />
      </IconButton>
    </Box>
  );

  return (
    <>
      {saved && (
        <Box>
          <ActionButton
            title={`TAGS (${tags.length})`}
            startIcon={<LocalOffer />}
            onClick={() => {
              setOpen(true);
              setReminderOpen(true);
            }}
          />
          <EltrpModal
            title={"Manage custom tags for article"}
            open={open}
            handleClose={() => setOpen(false)}
          >
            <Box textAlign={"center"}>
              Create and add a new custom tag or add an existing tag to the
              article.
              <Box display={"flex"} mt={1} p={1}>
                <Form
                  initialValues={{ tags: tags }}
                  onSubmit={submit}
                  mutators={{ ...arrayMutators }}
                  render={({
                    handleSubmit,
                    values,
                    form: {
                      mutators: { push },
                    },
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      onReset={() => setOpen(false)}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box maxHeight={"10rem"} overflow={"auto"}>
                        <FieldArray name="tags">
                          {({ fields }) =>
                            fields.value
                              ? fields.value.map((name, index) => (
                                  <Chip
                                    label={name}
                                    style={{ margin: "1% 1% 0% 0%" }}
                                    key={index}
                                    onDelete={() => fields.remove(index)}
                                  />
                                ))
                              : undefined
                          }
                        </FieldArray>
                      </Box>
                      <Field
                        name="newSubject"
                        component="input"
                        subscription={{ active: true, value: true }}
                      >
                        {({ input, meta }) => (
                          <Box
                            display={"flex"}
                            flexWrap={"wrap"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            m={2}
                            gap={1}
                          >
                            <Autocomplete
                              freeSolo
                              style={{ width: "20rem" }}
                              size={"small"}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  pushValue(values, input.value, push);
                                  input.onChange(undefined);
                                }
                              }}
                              options={tagList}
                              renderInput={(params) => (
                                <TextField
                                  color="secondary"
                                  {...params}
                                  size="small"
                                  placeholder="Create or select a tag"
                                  value={input.value}
                                  onChange={(value) => input.onChange(value)}
                                />
                              )}
                              {...input}
                              onChange={(event, value, reason) =>
                                input.onChange(value)
                              }
                            />{" "}
                            <Button
                              onClick={() => {
                                pushValue(values, input.value, push);
                                input.onChange(undefined);
                              }}
                              color={"secondary"}
                              variant={"contained"}
                            >
                              {" "}
                              ADD TAG
                            </Button>
                          </Box>
                        )}
                      </Field>

                      <Box
                        display={"flex"}
                        justifyContent={"right"}
                        gap={1}
                        mt={2}
                      >
                        <Button
                          type={"reset"}
                          color={"warning"}
                          variant={"contained"}
                        >
                          {" "}
                          CANCEL{" "}
                        </Button>
                        <Button
                          type={"submit"}
                          variant={"contained"}
                          color={"success"}
                        >
                          SAVE
                        </Button>
                      </Box>
                    </form>
                  )}
                />
              </Box>
            </Box>
          </EltrpModal>
        </Box>
      )}
      <Snackbar
        open={reminderOpen}
        autoHideDuration={6000}
        onClose={() => setReminderOpen(false)}
        message={"You are using favourites without being signed in"}
        action={action}
      />
    </>
  );
};
