import { SavedArticles } from "../../models/SavedArticles";

const key = "ELTRP_favourites";

const tags = "Tags";

export const addTagCategories = (value: string) => {
  const tagString = localStorage.getItem(tags);
  const tagList: string[] = tagString ? JSON.parse(tagString) : [];

  if (tagList.length >= 10) {
    throw new Error("Max limit reached");
  }

  localStorage.setItem(tags, JSON.stringify(tagList.concat(value)));
};

export const removeTagCategory = (tagToRemove: string) => {
  const tagString = localStorage.getItem(tags);
  const tagList: string[] = tagString ? JSON.parse(tagString) : [];

  const newTagList = tagList.filter((tag) => tag !== tagToRemove);
  localStorage.setItem(tags, JSON.stringify(newTagList));

  const item = getFromLocalStorage();
  item.removeTagCategory(tagToRemove);
  setToLocalStorage(item);
};

export const getTagsCategories = () => {
  const tagString = localStorage.getItem(tags);
  if (tagString) {
    return JSON.parse(tagString);
  } else {
    return [];
  }
};
export const getTags = (articleId: string) => {
  const item = getFromLocalStorage();
  return item.getTags(articleId);
};

export const updateTags = (articleId: string, tags: string[]) => {
  const item = getFromLocalStorage();
  const tag = item.getTags(articleId) || [];

  if (tag.length >= 10) {
    throw new Error("Max limit reached");
  }
  item.updateTagsOnArticle(articleId, tags);
  setToLocalStorage(item);
};

export const addToLocalStorage = (articleId: string) => {
  const item = getFromLocalStorage();
  if (item.numSaves > 100) {
    throw new Error("Max limit reached");
  }
  item.addArticle(articleId);
  setToLocalStorage(item);
};

export const removeFromLocalStorage = (articleId: string) => {
  const item = getFromLocalStorage();
  item.removeArticle(articleId);
  setToLocalStorage(item);
};

export const isInLocalStorage = (articleId: string) => {
  const item = getFromLocalStorage();
  return item.hasArticle(articleId);
};

export const hasLocalStorage = () => {
  return localStorage.getItem(key);
};

export const getIdsFromLocalStorage = () => {
  const item = getFromLocalStorage();
  return item.getIds();
};

export const getFromLocalStorage = () => {
  const item = localStorage.getItem(key);
  if (item) {
    return new SavedArticles(item);
  }
  return new SavedArticles();
};

const setToLocalStorage = (value: SavedArticles) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getIdsWithTagFromLocalStorage = (tag: string) => {
  const item = getFromLocalStorage();
  return item.getIdsWithTag(tag);
};

export const addMultipleToLocalStorage = (ids: string[], tag?: string) => {
  const item = getFromLocalStorage();
  const itemIds = item.getIds();
  ids.map((id) => {
    return itemIds.includes(id)
      ? item.updateTagsOnArticle(id, makeNewTagList(tag, item.getTags(id)))
      : item.addArticleWithTag(id, tag ? [tag] : []);
  });
};

const makeNewTagList = (tag?: string, tagList?: string[]) => {
  if (tag && tagList) {
    return tagList?.includes(tag) ? tagList : tagList.concat(tag);
  } else {
    return tag ? [tag] : [];
  }
};
