import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  SvgIconProps,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import {
  AccountCircle,
  LoginSharp,
  LogoutSharp,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/context/auth-context";
import { useEnv } from "../../../utils/context/env-context";

export const UserMenu = (props: SvgIconProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const navigate = useNavigate();
  const auth = useAuth();
  const env = useEnv();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <AccountCircle {...props} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList sx={{ minWidth: 300 }}>
          {auth.authenticated ? (
            <Box>
              <MenuItem onClick={() => navigate("/user-settings")}>
                <ListItemIcon>
                  <Settings fontSize={"small"} />
                </ListItemIcon>
                <ListItemText> Settings </ListItemText>
              </MenuItem>
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <LogoutSharp fontSize={"small"} />
                </ListItemIcon>
                <ListItemText>Sign out</ListItemText>
              </MenuItem>
            </Box>
          ) : (
            <Box>
              <MenuItem onClick={() => navigate("/sign-in")}>
                <ListItemIcon>
                  {" "}
                  <LoginSharp />{" "}
                </ListItemIcon>
                <ListItemText> Sign in</ListItemText>
              </MenuItem>
              {env.env === "PUBLIC" && (
                <MenuItem onClick={() => navigate("/sign-up")}>
                  <ListItemIcon>
                    {" "}
                    <PersonAdd />{" "}
                  </ListItemIcon>
                  <ListItemText> Sign up </ListItemText>
                </MenuItem>
              )}
            </Box>
          )}
        </MenuList>
      </Menu>{" "}
    </Box>
  );
};
