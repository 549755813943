import { Form } from "react-final-form";
import { Alert, Box } from "@mui/material";
import { useState } from "react";
import { changePassword } from "../user.service";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";
import { PasswordField } from "../../../components/fields/password/password-field";

export const ChangePassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (data: { old_password: string; new_password: string }) => {
    setLoading(true);
    changePassword(
      {
        previousPassword: data.old_password,
        proposedPassword: data.new_password,
      },
      setLoading,
      alert.handleAlert,
    ).subscribe(() => {
      setLoading(false);
      setConfirmation(true);
    });
  };

  const validate = (values: Record<string, any>) => {
    const errors: any = {};

    if (values.confirm_new_password !== values.new_password) {
      errors.confirm_new_password = "Passwords must match";
    }

    return errors;
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit, submitting, form: { reset } }) => (
        <form onSubmit={(event) => handleSubmit(event)?.then(() => reset())}>
          <Box display={"flex"} gap={2} flexDirection={"column"}>
            <PasswordField
              fieldName={"old_password"}
              fieldLabel={"Old Password"}
            />
            <PasswordField
              fieldName={"new_password"}
              fieldLabel={"New Password"}
            />
            <PasswordField
              fieldName={"confirm_new_password"}
              fieldLabel={"Confirm New Password"}
            />
            <Box display={"flex"} justifyContent={"right"} gap={2}>
              {confirmation && (
                <Alert onClose={() => setConfirmation(false)}>
                  {" "}
                  Password has been changed
                </Alert>
              )}
              <LoadingButton
                type={"submit"}
                disabled={submitting}
                title={"Change Password"}
                loading={loading}
              />
            </Box>
          </Box>
        </form>
      )}
    ></Form>
  );
};
