import { Button, IconButton, Snackbar } from "@mui/material";
import { useAuth } from "../../../utils/context/auth-context";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { CloseSharp } from "@mui/icons-material";

export const SignInPrompt = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClose = () => {
    auth.clearSignInPrompt();
  };

  const handleClick = () => {
    auth.clearSignInPrompt();
    navigate("/sign-in");
  };

  const action = (
    <Fragment>
      <Button
        color={"inherit"}
        size={"small"}
        variant={"outlined"}
        onClick={handleClick}
      >
        SIGN IN
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseSharp fontSize={"small"} />
      </IconButton>
    </Fragment>
  );

  return (
    <Snackbar
      open={auth.showSignInPrompt}
      onClose={handleClose}
      message={"You have been signed out"}
      action={action}
    />
  );
};
