import { Dispatch, SetStateAction, useState } from "react";
import { CloseSharp, Star, StarOutline } from "@mui/icons-material";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { Box, Button, IconButton, Snackbar } from "@mui/material";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "../../../utils/context/alert-context";
import { useFavourites } from "../../../utils/context/favourites-context";
import { useAuth } from "../../../utils/context/auth-context";

interface ISaveArticle {
  articleId?: string;
  saved: boolean;
  setSaved: Dispatch<SetStateAction<boolean | undefined>>;
}

export const SaveArticle = (props: ISaveArticle) => {
  const { articleId, saved, setSaved } = props;

  const [popOpen, setPopOpen] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [reminderOpen, setReminderOpen] = useState<boolean>(false);

  const alert = useAlert();
  const auth = useAuth();
  const favourites = useFavourites();
  const navigate = useNavigate();

  const saveArticle = () => {
    if (favourites.checkIsFirstSave()) {
      setPopOpen(true);
    }
    if (!saved && articleId) {
      try {
        favourites.addFavourite(articleId);
        auth.favouritesReminder === "true" &&
          !auth.authenticated &&
          setReminderOpen(true);
      } catch (err) {
        alert.handleAlert({
          message: "Error saving article",
          statusCode: 418,
          extra: "Max limit reached",
        });
      }
    }
    if (saved && articleId) {
      setOpen(true);
      setReminderOpen(true);
    }

    articleId && setSaved(favourites.checkSaved(articleId));
  };

  const handleClose = () => {
    auth.clearFavouritesReminder();
    setReminderOpen(false);
  };

  const handleClick = () => {
    setReminderOpen(false);
    navigate("/sign-in");
  };

  const action = (
    <Box display={"flex"} gap={1}>
      <Button
        color={"inherit"}
        size={"small"}
        variant={"outlined"}
        onClick={handleClick}
      >
        {" "}
        Sign in{" "}
      </Button>
      <Button
        color={"inherit"}
        size={"small"}
        variant={"outlined"}
        onClick={handleClose}
      >
        {" "}
        Don't show again{" "}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setReminderOpen(false)}
      >
        <CloseSharp fontSize={"small"} />
      </IconButton>
    </Box>
  );

  return (
    <Box>
      <ActionButton
        title={saved ? "Saved as favourite" : "Save as favourite"}
        startIcon={saved ? <Star /> : <StarOutline />}
        onClick={saveArticle}
      />
      <EltrpModal
        title={"Your first save!"}
        open={popOpen}
        handleClose={() => setPopOpen(false)}
      >
        <Box textAlign={"center"} p={2}>
          <p>
            {" "}
            Congratulations on your first saved article! You can save up to 100
            articles on the ELTRP page. You can find your saved articles and
            share them under
            <Link to={"/favourites"}> Favourite Articles</Link>. The articles
            are saved using local storage, read more about the use of local
            storage under{" "}
            <Link to={"/data-policy"}>ELTRP data protection policy</Link>.{" "}
          </p>
          <p>
            You can also tag your saved articles with custom tags. You can have
            a maximum of 10 tags.
          </p>
          <p>
            <b>
              If you want to save more than 100 articles and have more than 10
              tags, and be able to access them on different devices you can
              create a user at ELTRP and sign in! Sign in or create an user{" "}
              <Link to={"/sign-in"}>here</Link>.
            </b>
          </p>
          <Box display={"flex"} gap={2} justifyContent={"center"}>
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={() => navigate("/sign-in")}
            >
              Sign in
            </Button>
            <Button
              color={"secondary"}
              variant={"contained"}
              onClick={() => navigate("/sign-up")}
            >
              Sign up
            </Button>
          </Box>
        </Box>
      </EltrpModal>
      <EltrpModal
        title={"Remove Favourite"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box
          display={"flex"}
          gap={2}
          textAlign={"center"}
          flexDirection={"column"}
        >
          Are you sure you want to remove favourite? <br />
          All tags saved on this article will be lost
          <Box display={"flex"} gap={1} justifyContent={"center"}>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant={"contained"}
              color={"warning"}
              onClick={() => {
                articleId && favourites.removeFavourite(articleId);
                articleId && setSaved(favourites.checkSaved(articleId));
                setOpen(false);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </EltrpModal>
      <Snackbar
        open={reminderOpen}
        autoHideDuration={6000}
        onClose={() => setReminderOpen(false)}
        message={"You are using favourites without being signed in"}
        action={action}
      />
    </Box>
  );
};