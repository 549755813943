import { useEffect, useState } from "react";
import { SearchResponse } from "../models/SearchResult";
import { Link, useSearchParams } from "react-router-dom";
import { getSavedArticles } from "../features/favourites/save-article.service";
import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../layout/wrappers/eltrp-header";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import Grid2 from "@mui/material/Unstable_Grid2";
import { EltrpText } from "../layout/wrappers/eltrp-text";
import { Box, Divider, Typography } from "@mui/material";
import { AggregationsList } from "../components/aggregations/aggregations";
import { ShareBasket } from "../features/share/share-basket";
import { ResultPagination } from "../components/pagination/result-pagination";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { ResultBox } from "../features/result/result-box";
import { EltrpSearchResults } from "../layout/wrappers/eltrp-search-results";

import { MobileAggregations } from "../components/aggregations/devices/mobile-aggregations";
import { useAlert } from "../utils/context/alert-context";
import { useFavourites } from "../utils/context/favourites-context";
import { TagCategoriesAggregation } from "../features/favourites/tags/tag-categories-aggregation";
import { TagCategoriesAggregationsMobile } from "../features/favourites/tags/tag-categories-aggregations-mobile";
import { useResultMessage } from "../hooks/use-result-message";

export const FavoritesPage = () => {
  const [response, setResponse] = useState<SearchResponse>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [tagFilter, setTagFilter] = useState<string[]>([]);

  const alert = useAlert();
  const favourites = useFavourites();
  const resultMessage = useResultMessage(response?.hits);

  const includesAny = (arr?: string[], values?: string[]) =>
    values?.some((v) => arr?.includes(v));

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";

    const ids = favourites.getArticleIds();

    // Filter on ids that also match the tag filter

    const newIds = !!tagFilter.length
      ? ids.filter((id) =>
          includesAny(favourites.getTagsFromArticle(id), tagFilter),
        )
      : ids;

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "baskedId" && key !== "size"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );

    if (newIds) {
      setLoading(true);
      getSavedArticles(
        newIds,
        filtersAsArray,
        alert.handleAlert,
        setLoading,
        from,
        size,
      ).subscribe((res) => {
        setResponse(res);
        setLoading(false);
      });
    }
  }, [searchParams, alert, tagFilter, favourites]);

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={"Favourite Articles"} />
      <EltrpBody>
        <Grid2>
          <EltrpText>
            <h2> All your favourite articles in one place</h2>
            <p>
              {" "}
              Save articles for future use or to share with friends and
              colleagues!{" "}
            </p>
            <h3> How to use it </h3>
            <p>
              Next to each article, there is a save icon. This enables you to
              save up to 100 articles and create 10 custom tags if you are not
              signed in. If you have an account you are not limited by this. You
              can also share your favourite articles by generating a link below.
              You can share either all your saved articles or just articles with
              a certain tag. If you update your list of saved articles you need
              to generate a new link if you want to share your updated
              favourites.
            </p>
            <p>
              The articles are saved using local storage connected to your
              current browser if you are not signed in. Local storage is not
              persistent, for persistent storage you must create an account and
              sign in. Read more about our use of local storage under
              <Link to={"/data-policy"}> ELTRP data protection policy</Link>.
              <p>
                You can manage your tags by clicking on the manage tags button,
                adding and deleting tags.
              </p>
            </p>
            <h3>Importing previously saved articles</h3>
            <p>
              If you have saved articles before signing up or in, you can import
              them to your account in the user settings.
            </p>
          </EltrpText>
          <Divider />
        </Grid2>
        <EltrpSearchResults
          hits={response?.hits.total.value || 0}
          aggregations={
            <Box pr={1}>
              <TagCategoriesAggregation
                tagFilter={tagFilter}
                setTagFilter={setTagFilter}
              />
              <AggregationsList aggregations={response?.aggs || []} />
            </Box>
          }
          results={
            <Box mb={"2rem"}>
              <Grid2
                container
                sx={{ display: { xs: "none", md: "flex" } }}
                mb={4}
              >
                <Grid2 xs={12}>
                  <h2> Saved Articles </h2>
                </Grid2>
                <Grid2
                  xs={12}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {resultMessage}
                  {!!response?.hits.total.value && <ShareBasket />}
                </Grid2>
              </Grid2>

              <Grid2 container display={{ xs: "flex", md: "none" }} mb={4}>
                <Grid2 xs={8}>
                  <h2 style={{ marginBottom: "0" }}> Saved Articles </h2>
                  <Typography variant={"subtitle2"}>{resultMessage}</Typography>
                </Grid2>
                <Grid2
                  display={"flex"}
                  xs={4}
                  justifyContent={"right"}
                  alignItems={"center"}
                >
                  <TagCategoriesAggregationsMobile
                    tagFilter={tagFilter}
                    setTagFilter={setTagFilter}
                  />
                  {response?.hits.total.value !== 0 && (
                    <MobileAggregations aggregations={response?.aggs || []} />
                  )}
                </Grid2>
                <Grid2 xs={12} mt={2}>
                  {!!response?.hits.total.value && <ShareBasket />}
                </Grid2>
              </Grid2>

              <ResultBox results={response?.hits.docs} />
              <ResultPagination value={response?.hits.total.value} />
            </Box>
          }
        />
      </EltrpBody>
    </EltrpPage>
  );
};