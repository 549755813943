import { useEffect, useState } from "react";
import { SaveArticle } from "../articles/save-article";
import { SaveTags } from "../tags/save-tags";
import { useFavourites } from "../../../utils/context/favourites-context";

interface FavouriteControlsProps {
  articleId: string;
}

export const FavouriteControls = ({ articleId }: FavouriteControlsProps) => {
  const [saved, setSaved] = useState<boolean>();

  const favourites = useFavourites();

  useEffect(() => {
    setSaved(favourites.checkSaved(articleId));
  }, [articleId, favourites]);

  return (
    <>
      <SaveArticle articleId={articleId} saved={!!saved} setSaved={setSaved} />
      <SaveTags articleId={articleId} saved={!!saved} />
    </>
  );
};
