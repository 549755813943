import { useSearchParams } from "react-router-dom";
import { SearchResult } from "../models/SearchResult";

export const useResultMessage = (result?: SearchResult) => {
  const [searchParams] = useSearchParams();

  const size = Number(searchParams.get("size"));
  const from = Number(searchParams.get("from"));
  const page =
    Number(searchParams.get("from")) / (Number(searchParams.get("size")) || 1) +
    1;

  return !result?.total?.value || result?.total.value === 0
    ? "No results"
    : "Showing " +
        (from + 1) +
        "-" +
        (result?.total.value && size * page > result.total.value
          ? result?.total.value
          : size * (page | 1)) +
        " of " +
        result?.total.value +
        " results";
};
