import { ResetPasswordForm } from "../forgot-password/reset-password-form";
import { EltrpAuthPage } from "../../../layout/wrappers/eltrp-auth-page";
import { Alert, Box, Button } from "@mui/material";
import { ForgotPasswordForm } from "../forgot-password/forgot-password-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormWrapper } from "../components/form-wrapper";
import { EltrpStepper } from "../../../components/ui/stepper/eltrp-stepper";

export const ResetPasswordPage = () => {
  const [step, setStep] = useState<number>(0);

  const navigate = useNavigate();
  return (
    <EltrpAuthPage>
      <FormWrapper
        title={"Reset Password"}
        subtitle={"Reset the password to your eltrp account."}
      >
        <EltrpStepper
          activeStep={step}
          setActiveStep={setStep}
          steps={[
            {
              label: "Send recovery code",
              component: <ForgotPasswordForm step={step} setStep={setStep} />,
            },
            {
              label: "Reset Password",
              component: <ResetPasswordForm step={step} setStep={setStep} />,
            },
          ]}
          finish={
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Alert> Password has been reset </Alert>
              <Button
                color={"secondary"}
                variant={"contained"}
                onClick={() => navigate("/sign-in")}
              >
                Sign in with new password
              </Button>
            </Box>
          }
        />
      </FormWrapper>
    </EltrpAuthPage>
  );
};
