import React, { useState } from "react";
import { useAuth } from "../../../utils/context/auth-context";
import { useNavigate } from "react-router-dom";
import { ISignInRequest } from "../../../models/SignInRequest";
import { Alert, Box, Button, CircularProgress, TextField } from "@mui/material";
import { Field, Form } from "react-final-form";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { PasswordField } from "../../../components/fields/password/password-field";

export const AdminSignInForm = () => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const auth = useAuth();
  const navigate = useNavigate();

  const login = (loginData: ISignInRequest) => {
    setLoading(true);
    auth.login({ data: loginData, setError, setLoading });
    navigate("/");
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2}>
      <Form
        onSubmit={login}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Field name="username">
                {({ input }) => (
                  <TextField
                    color="secondary"
                    autoComplete="on"
                    size="small"
                    fullWidth
                    label="Username"
                    required
                    {...input}
                  />
                )}
              </Field>
              <PasswordField fieldName={"password"} fieldLabel={"Password"} />
              {!!error && (
                <Alert severity={"error"}>
                  {" "}
                  Error: Wrong Username/Password{" "}
                </Alert>
              )}
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
              >
                {" "}
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Login"
                )}{" "}
              </Button>
            </Box>
          </form>
        )}
      ></Form>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <ActionButton
          title={"Forgot Password?"}
          onClick={() => navigate("/reset")}
        />
      </Box>
    </Box>
  );
};
