export class SavedArticles {
  public numSaves: number = 0;
  public lastSaved: Date = new Date();
  public articles: SavedArticle[] = [];

  public constructor(jsonString?: string) {
    if (jsonString) {
      const item = JSON.parse(jsonString) as SavedArticles;
      this.numSaves = item.numSaves || 0;
      this.lastSaved = item.lastSaved;
      this.articles =
        item.articles?.map(
          (article) => new SavedArticle(article.articleId, article.tags),
        ) || [];
    }
  }

  public getTags = (articleId: string) => {
    return this.articles.find((a) => a.articleId === articleId)?.tags;
  };

  public updateTagsOnArticle = (articleId: string, tags: string[]) => {
    this.articles.find((a) => a.articleId === articleId)?.updateTags(tags);
  };

  public removeTagCategory = (tag: string) => {
    this.articles.forEach((article) => article.removeTag(tag));
  };

  public addArticle = (articleId: string) => {
    this.articles.push(new SavedArticle(articleId, []));
    this.update();
  };

  public addArticleWithTag = (articleId: string, tags?: string[]) => {
    this.articles.push(new SavedArticle(articleId, tags || []));
    this.update();
  };

  public removeArticle = (articleId: string) => {
    this.articles = this.articles.filter((a) => a.articleId !== articleId);
    this.update();
  };

  public getIds = () => {
    return this.articles.map((a) => a.articleId);
  };

  public getIdsWithTag = (tag: string) => {
    return this.articles
      .filter((a) => a.tags.includes(tag))
      .map((a) => a.articleId);
  };

  public hasArticle = (articleId: string) => {
    const searchResult = this.articles.find((a) => a.articleId === articleId);
    return !!searchResult;
  };

  private update() {
    this.numSaves = this.articles.length;
    this.lastSaved = new Date();
  }
}

export class SavedArticle {
  public articleId: string = "";
  public tags: string[] = [];

  public constructor(articleId: string, tags: string[]) {
    this.articleId = articleId;
    this.tags = tags;
  }

  public updateTags = (tags: string[]) => {
    this.tags = tags;
  };

  public removeTag = (tag: string) => {
    this.tags = this.tags.filter((t) => t !== tag);
  };
}
