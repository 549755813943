import {
  Box,
  Button,
  Chip,
  Divider,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { theme } from "../../../utils/theme/theme";
import { useMemo, useState } from "react";
import { useFavourites } from "../../../utils/context/favourites-context";

export const TagCategoriesForm = () => {
  const [dialog, setDialog] = useState<string | undefined>();
  const [value, setValue] = useState<string>("");

  const favourites = useFavourites();

  const tagCategories = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);
  const removeTagCategories = (tag: string) => {
    favourites.removeTag(tag);
    setDialog(undefined);
  };

  return (
    <Box p={2}>
      <Divider>
        <Typography variant={"subtitle1"}>
          {" "}
          EDIT EXISTING TAG CATEGORIES{" "}
        </Typography>
      </Divider>
      <Box
        display={"flex"}
        gap={2}
        m={1}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          gap={1}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box
            display={"flex"}
            gap={1}
            flexWrap={"wrap"}
            justifyContent={"center"}
          >
            {tagCategories.map((tag: string) => (
              <Box>
                <Chip label={tag} onDelete={() => setDialog(tag)} />
              </Box>
            ))}
          </Box>
          {dialog && (
            <Box
              sx={{
                backgroundColor: theme.palette.warning.light,
                boxShadow: "0px 2px 2px 0px rgba(99, 99, 99, 0.1)",
              }}
              p={1}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <Typography variant={"button"} color={theme.palette.warning.main}>
                Are you sure you want to delete {dialog} from the tag categories
                and from all articles?
              </Typography>
              <Button
                color={"warning"}
                variant={"contained"}
                size={"small"}
                onClick={() => setDialog(undefined)}
              >
                NO
              </Button>
              <Button
                color={"warning"}
                variant={"contained"}
                size={"small"}
                onClick={() => removeTagCategories(dialog)}
              >
                YES
              </Button>
            </Box>
          )}
        </Box>
        <Divider>
          <Typography variant={"subtitle1"}>
            {" "}
            ADD NEW TAG CATEGORIES{" "}
          </Typography>
        </Divider>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (value?.trim().length) {
              value && favourites.addTag(value);
              setValue("");
            }
          }}
        >
          <Box display={"flex"} gap={1} m={1} justifyContent={"center"}>
            <OutlinedInput
              size={"small"}
              color={"secondary"}
              placeholder={"Add new tag category"}
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
            <Button variant={"contained"} color={"secondary"} type={"submit"}>
              {" "}
              ADD{" "}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
