import { TextField } from "@mui/material";
import { Field } from "react-final-form";
import React, { useState } from "react";
import { PasswordVisibility } from "./password-visibility";

interface PasswordFieldProps {
  fieldName: string;
  fieldLabel: string;
}

export const PasswordField = ({
  fieldName,
  fieldLabel,
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Field name={fieldName}>
      {({ input }) => (
        <TextField
          size="small"
          color="secondary"
          fullWidth
          type={showPassword ? "text" : "password"}
          label={fieldLabel}
          required
          {...input}
          InputProps={{
            endAdornment: (
              <PasswordVisibility
                visible={showPassword}
                setVisible={setShowPassword}
              />
            ),
          }}
        />
      )}
    </Field>
  );
};
