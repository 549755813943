import { useFavourites } from "../../../utils/context/favourites-context";
import { Alert, Box, Button } from "@mui/material";
import { Observable } from "rxjs";
import { useState } from "react";

export const ImportFavourites = () => {
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const favourites = useFavourites();

  const importFavourites = new Observable((subscriber) => {
    favourites.importFavourites();
    subscriber.next();
  });
  const importF = () => {
    importFavourites.subscribe(() => setConfirmation(true));
  };

  return (
    <Box display={"flex"} gap={1}>
      <Button color={"secondary"} variant={"contained"} onClick={importF}>
        {" "}
        Import favourites{" "}
      </Button>
      {confirmation && (
        <Alert onClose={() => setConfirmation(false)}>
          Imported Favourites
        </Alert>
      )}
    </Box>
  );
};
