import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { EltrpModal } from "../../../components/ui/modal/eltrp-modal";
import { useMemo, useState } from "react";
import { useFavourites } from "../../../utils/context/favourites-context";
import { SearchResponse } from "../../../models/SearchResult";

interface SaveSharedArticlesProps {
  response?: SearchResponse;
}

export const SaveSharedArticles = ({ response }: SaveSharedArticlesProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const favourites = useFavourites();

  const options = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  const submit = (value: any) => {
    const ids = response?.hits.docs.map((doc) => doc._id);
    ids && favourites.addFavouritesChunk(ids, value.tag);
    window.location.reload();
  };

  return (
    <>
      <Button
        variant={"contained"}
        color={"secondary"}
        onClick={() => setOpen(true)}
      >
        Save shared articles
      </Button>
      <EltrpModal
        title={"Save shared article"}
        open={open}
        handleClose={() => setOpen(false)}
      >
        <Box>
          <Form
            onSubmit={submit}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="tag"
                  component="input"
                  subscription={{ active: true, value: true }}
                >
                  {({ input, meta }) => (
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      m={2}
                      gap={1}
                      flexDirection={"column"}
                    >
                      <Autocomplete
                        freeSolo
                        style={{ width: "20rem" }}
                        size={"small"}
                        options={options}
                        renderInput={(params) => (
                          <TextField
                            color="secondary"
                            {...params}
                            size="small"
                            placeholder="Create or select a tag"
                            value={input.value}
                            onChange={(value) => input.onChange(value)}
                          />
                        )}
                        {...input}
                        onChange={(event, value, reason) =>
                          input.onChange(value)
                        }
                      />{" "}
                      <Button
                        type={"submit"}
                        color={"secondary"}
                        variant={"contained"}
                      >
                        {" "}
                        Save shared articles
                      </Button>
                    </Box>
                  )}
                </Field>
              </form>
            )}
          />
        </Box>
      </EltrpModal>
    </>
  );
};
