import {
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useMemo } from "react";
import { useFavourites } from "../../../utils/context/favourites-context";
import { TagCategoriesProps } from "./tag-categories-aggregation";

export const TagCategoriesSelect = ({
  tagFilter,
  setTagFilter,
}: TagCategoriesProps) => {
  const favourites = useFavourites();

  const tagCategories = useMemo(() => {
    return favourites.getTagList();
  }, [favourites]);

  const handleChange = (event: SelectChangeEvent<typeof tagFilter>) => {
    const {
      target: { value },
    } = event;
    setTagFilter(typeof value === "string" ? value.split(",") : value);
  };

  const removeTagFilter = (value: string) => {
    setTagFilter((prevState) => prevState.filter((v) => v !== value));
  };

  return (
    <Select
      multiple
      displayEmpty
      value={tagFilter}
      onChange={handleChange}
      input={<OutlinedInput size={"small"} fullWidth color={"secondary"} />}
      renderValue={(selected) =>
        selected.length === 0 ? (
          <em>Select tags</em>
        ) : (
          <Box display={"flex"} flexWrap={"wrap"} gap={0.5}>
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                size={"small"}
                onDelete={() => removeTagFilter(value)}
                onMouseDown={(event) => event.stopPropagation()}
              />
            ))}
          </Box>
        )
      }
    >
      {tagCategories.length > 0 ? (
        tagCategories.map((tag) => (
          <MenuItem key={tag} value={tag}>
            {" "}
            {tag}{" "}
          </MenuItem>
        ))
      ) : (
        <MenuItem> No tags</MenuItem>
      )}
    </Select>
  );
};
