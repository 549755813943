import { useMemo, useState } from "react";
import { Badge, Box, IconButton } from "@mui/material";
import { ActionButton } from "../../../components/ui/buttons/action-button";
import { TagCategoriesForm } from "./tag-categories-form";
import { TagCategoriesProps } from "./tag-categories-aggregation";
import { EltrpSlider } from "../../../components/ui/animations/eltrp-slider";
import { ArrowDropDown, ArrowDropUp, LocalOffer } from "@mui/icons-material";
import { TagCategoriesSelect } from "./tag-categories-select";

export const TagCategoriesAggregationsMobile = ({
  tagFilter,
  setTagFilter,
}: TagCategoriesProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [slide, setSlider] = useState<boolean>(false);

  const numSelected = useMemo(() => {
    return tagFilter.length;
  }, [tagFilter]);

  return (
    <>
      <IconButton onClick={() => setSlider(true)}>
        <Badge badgeContent={numSelected}>
          <LocalOffer />
        </Badge>
      </IconButton>
      <EltrpSlider
        open={slide}
        close={() => setSlider(false)}
        title={"TAG CATEGORIES"}
      >
        <Box
          p={2}
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          alignItems={"start"}
        >
          <i>Show articles with tags </i>

          <TagCategoriesSelect
            tagFilter={tagFilter}
            setTagFilter={setTagFilter}
          />
          <ActionButton
            title={"Manage Tag Categories"}
            onClick={() => setOpen(!open)}
            endIcon={open ? <ArrowDropDown /> : <ArrowDropUp />}
          />
        </Box>

        {open && <TagCategoriesForm />}
      </EltrpSlider>
    </>
  );
};
