import { Box, TextField } from "@mui/material";
import { useAuth } from "../../../utils/context/auth-context";

export const UserAttributes = () => {
  const auth = useAuth();

  return (
    <Box display={"flex"} gap={2} flexDirection={"column"}>
      <TextField
        label={"Username"}
        size={"small"}
        fullWidth
        disabled
        value={auth.user?.username || ""}
      />
      <TextField
        label={"Email"}
        size={"small"}
        fullWidth
        disabled
        value={auth.user?.email || ""}
      />
    </Box>
  );
};
