import { EltrpPage } from "../layout/wrappers/eltrp-page";
import { EltrpHeader } from "../layout/wrappers/eltrp-header";
import { EltrpBody } from "../layout/wrappers/eltrp-body";
import { useEffect, useState } from "react";
import { getBasket } from "../features/favourites/save-article.service";
import { SearchResponse } from "../models/SearchResult";
import { ResultPagination } from "../components/pagination/result-pagination";
import { Link, useSearchParams } from "react-router-dom";
import { useParamsStable } from "../hooks/use-params-stable";
import { AggregationsList } from "../components/aggregations/aggregations";
import { Box } from "@mui/material";
import { EltrpLoading } from "../layout/wrappers/eltrp-loading";
import { EltrpSearchResults } from "../layout/wrappers/eltrp-search-results";
import { ResultBox } from "../features/result/result-box";
import { useAlert } from "../utils/context/alert-context";
import { SaveSharedArticles } from "../features/favourites/basket/save-shared-articles";
import { useResultMessage } from "../hooks/use-result-message";

export const BasketPage = () => {
  const [response, setResponse] = useState<SearchResponse>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();
  const basketId = useParamsStable("basketId");
  const resultMessage = useResultMessage(response?.hits);

  useEffect(() => {
    const from = searchParams.get("from") || "0";
    const size = searchParams.get("size") || "10";

    const filters = searchParams;
    const filtersAsArray: string[] = [];

    filters.forEach((value, key) =>
      key !== "from" && key !== "size"
        ? filtersAsArray.push([key, value].join(":"))
        : undefined,
    );

    if (basketId) {
      setLoading(true);
      getBasket(
        basketId,
        filtersAsArray,
        alert.handleAlert,
        setLoading,
        from,
        size,
      ).subscribe((res) => {
        setResponse(res);
        setLoading(false);
      });
    }
  }, [searchParams, basketId, alert]);

  return (
    <EltrpPage>
      <EltrpLoading loading={loading} />
      <EltrpHeader title={`Shared Articles`} />
      <EltrpBody>
        <EltrpSearchResults
          aggregations={
            <AggregationsList aggregations={response?.aggs || []} />
          }
          results={
            <Box mb={"2rem"}>
              <h2 style={{ marginBottom: 0 }}>Articles</h2>
              <Box display={"flex"} gap={1} flexDirection={"column"} mb={4}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  {resultMessage}
                  <SaveSharedArticles response={response} />
                </Box>
                {basketId && (
                  <i>
                    {" "}
                    These articles have been shared with you with the id{" "}
                    {basketId}. View your own favourite articles{" "}
                    <Link to={"/favourites"}> here</Link>.
                  </i>
                )}
              </Box>
              <ResultBox results={response?.hits.docs} />
              <ResultPagination value={response?.hits.total.value} />{" "}
            </Box>
          }
          hits={response?.hits.total.value || 0}
        />
      </EltrpBody>
    </EltrpPage>
  );
};
