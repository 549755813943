import { Field, Form } from "react-final-form";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { StepperProps } from "../types/StepperProps";
import { signUp } from "../../users/user.service";
import { useAlert } from "../../../utils/context/alert-context";
import { LoadingButton } from "../../../components/ui/buttons/loading-button";
import { PasswordField } from "../../../components/fields/password/password-field";
import { QuestionMark } from "@mui/icons-material";

export const SignUpForm = ({ setStep }: StepperProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useAlert();

  const submit = (value: any) => {
    setLoading(true);
    signUp(
      value.email,
      value.password,
      setLoading,
      alert.handleAlert,
    ).subscribe((res) => {
      setStep((prevState) => prevState + 1);
      setLoading(false);
    });
  };

  const validate = (values: Record<string, any>) => {
    const errors: any = {};
    const regexp = new RegExp(
      "^(?=.*\\d)(?=.*[!@#$%^&*?=><;:|_+])(?=.*[a-z])(?=.*[A-Z]).{8,}$",
    );

    if (values.confirm_password !== values.password) {
      errors.confirm_password = "Passwords must match";
    }

    if (!regexp.test(values.password) && values.password?.length > 2) {
      errors.password = "Incorrect password format";
    }

    return errors;
  };
  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Field name={"email"}>
              {({ input }) => (
                <TextField
                  label={"Email"}
                  type={"email"}
                  size={"small"}
                  color={"secondary"}
                  fullWidth
                  required
                  {...input}
                />
              )}
            </Field>
            <Box display={"flex"} justifyContent={"center"} gap={1}>
              <PasswordField fieldName={"password"} fieldLabel={"Password"} />

              <Tooltip
                placement={"right-end"}
                title={
                  <Box textAlign={"left"}>
                    Password must contain at least:
                    <ul>
                      <li>Minimum 8 letters</li>
                      <li>1 number</li>
                      <li>1 special character ({"!@#$ % ^ & * ?=><;:|_+"})</li>
                      <li>1 uppercase letter</li>
                      <li> 1 lowercase letter</li>
                    </ul>
                  </Box>
                }
              >
                <IconButton size={"small"}>
                  <QuestionMark fontSize={"small"} />
                </IconButton>
              </Tooltip>
            </Box>
            <PasswordField
              fieldName={"confirm_password"}
              fieldLabel={"Confirm Password"}
            />
            <LoadingButton
              type={"submit"}
              title={"Sign up"}
              loading={loading}
            />
          </Box>
        </form>
      )}
    ></Form>
  );
};
